import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
export var LIST_OF_PAGES = [
    "page",
    "post",
    "causeArea",
    "cause",
    "charity",
    "report", 
];
export var LIST_OF_HARDCODED_PAGES = [
    "homePage",
    "pledgeRecommendationTool",
    "donationPlatform",
    "bestCharities",
    "pledgePage",
    "hraiPage",
    "membersPage", 
];
export var TRANSLATED_PAGES = _to_consumable_array(LIST_OF_PAGES).concat(_to_consumable_array(LIST_OF_HARDCODED_PAGES), [
    "navigation",
    "pledgeClub", 
]);
export var LIST_OF_PAGE_TYPES = LIST_OF_PAGES.map(function(id) {
    return {
        type: id
    };
});
